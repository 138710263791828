


































import {Component, Vue} from 'vue-property-decorator';
import {getCourseByDateListAsync} from '@/api/course'
import {CourseDto} from '@/dto/CourseDto'
import CourseTime from '@/components/CourseTime.vue'
import {getWeek} from '@/utils/common'

@Component({
  components: {
    CourseTime
  }
})
export default class CourseIndex extends Vue {
  private courses: any = {
    items: new Array<CourseDto>()
  }

  private loading = false;

  private async activated() {
    const qdate = (this.$refs.courseTime2 as any).queryDate
    await this.getList(qdate)
  }

  private handleClickCourseInfo(item: CourseDto) {
    this.$router.push({
      path: '/course-info',
      query: {
        courseId: item.id
      }
    })
  }

  private async getList(queryDate: string) {
    this.courses.items = []
    const dateNow = new Date()
    this.loading = true
    const year = dateNow.getFullYear();
    const dt = `${year}-${queryDate.replace('.', '-')}`
    const result = await getCourseByDateListAsync(dt)
    this.courses = result;
    this.loading = false
  }

  private getCourseWeek(dt: string) {
    const newDate = new Date(dt)
    return `星期${getWeek(newDate.getDay())}`
  }


};
